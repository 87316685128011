import Vue from 'vue'
import Router from 'vue-router'
import menuModule from '@/store/modules/menu';

// Containers
const DefaultContainer = () => import('@/containers/DefaultContainer')

// Views
const Dashboard = () => import('@/views/Dashboard')

const Colors = () => import('@/views/theme/Colors')
const Typography = () => import('@/views/theme/Typography')

const Charts = () => import('@/views/Charts')
const Widgets = () => import('@/views/Widgets')

// hospital
const AllHospitalSettings = () => import('@/views/hospital/all_hospitals')
const HospitalSettings = () => import('@/views/hospital')
const HospitalSettingsMyHospital = () => import('@/views/hospital/my_hospital')
const HospitalAllUserSettings = () => import('@/views/hospital/users')
const HospitalAllUserSettingsAddEdit = () => import('@/views/hospital/users/add_edit')
const HospitalBulkNewUser = () => import('@/views/hospital/bulk_new_user')
const BulkRecordPatients = () => import('@/views/hospital/bulk_record_patients')
const BulkNewUser = () => import('@/views/hospital/bulk_new_user')
const WdmLayers = () => import('@/views/manager/wdmlayers')
const LayerDetails = () => import('@/views/manager/layerdetails')
const LayerReport = () => import('@/views/manager/layer_report')
const LayerProfileDetails = () => import('@/views/manager/layerprofiledetails')
const LayerWdmrRecordView = () => import('@/views/manager/layer_wdmr_record_view')
const WmanagerAppProcessList = () => import('@/views/manager/wmanager_app_process_list')
const WruleengineAppProcessList = () => import('@/views/manager/wruleengine_app_process_list')
const WanalyzerAppProcessList = () => import('@/views/manager/wanalyzer_app_process_list')
const WalgorithmAppProcessList = () => import('@/views/manager/walgorithm_app_process_list')

// manager
const DbManager = () => import('@/views/manager/db_manager')
const LayerQuery = () => import('@/views/manager/layer_query')
const LayerQuerySecond = () => import('@/views/manager/layer_query_second')
const ErrorLog = () => import('@/views/manager/error_log')
const RequestLog = () => import('@/views/manager/request_log')
const UserList = () => import('@/views/manager/user_list')
const UserAdd = () => import('@/views/manager/user_manage')
const UserEdit = () => import('@/views/manager/user_manage')

// user
const UserSettings = () => import('@/views/user')
// const UserEdit = () => import('@/views/user/edit')

//quiz
const Quiz = () => import('@/views/user/quiz')

// Views - Components
const Cards = () => import('@/views/base/Cards')
const Forms = () => import('@/views/base/Forms')
const Switches = () => import('@/views/base/Switches')
const Tables = () => import('@/views/base/Tables')
const Tabs = () => import('@/views/base/Tabs')
const Breadcrumbs = () => import('@/views/base/Breadcrumbs')
const Carousels = () => import('@/views/base/Carousels')
const Collapses = () => import('@/views/base/Collapses')
const Jumbotrons = () => import('@/views/base/Jumbotrons')
const ListGroups = () => import('@/views/base/ListGroups')
const Navs = () => import('@/views/base/Navs')
const Navbars = () => import('@/views/base/Navbars')
const Paginations = () => import('@/views/base/Paginations')
const Popovers = () => import('@/views/base/Popovers')
const ProgressBars = () => import('@/views/base/ProgressBars')
const Tooltips = () => import('@/views/base/Tooltips')

// Views - Buttons
const StandardButtons = () => import('@/views/buttons/StandardButtons')
const ButtonGroups = () => import('@/views/buttons/ButtonGroups')
const Dropdowns = () => import('@/views/buttons/Dropdowns')
const BrandButtons = () => import('@/views/buttons/BrandButtons')

// Views - Icons
const Flags = () => import('@/views/icons/Flags')
const FontAwesome = () => import('@/views/icons/FontAwesome')
const SimpleLineIcons = () => import('@/views/icons/SimpleLineIcons')
const CoreUIIcons = () => import('@/views/icons/CoreUIIcons')

// Views - Notifications
const Alerts = () => import('@/views/notifications/Alerts')
const Badges = () => import('@/views/notifications/Badges')
const Modals = () => import('@/views/notifications/Modals')

// Views - Pages
const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
const Login = () => import('@/views/Login')
const Logout = () => import('@/views/Logout')
const Register = () => import('@/views/Register')
const ResetPassword = () => import('@/views/ResetPassword')

Vue.use(Router)

export default new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: [{
      path: '/',
      redirect: 'dashboard',
      name: 'Home',
      component: DefaultContainer,
      children: [{
          path: 'dashboard',
          name: 'Dashboard',
          component: Dashboard
        },
        {
          path: '/hospital',
          name: 'hospital_settings',
          component: HospitalSettings
        },
        {
          path: '/all-hospitals',
          name: 'all_hospital_settings',
          component: AllHospitalSettings
        },
        {
          path: '/my-hospital',
          name: 'hospital_settings_my_hospital',
          component: HospitalSettingsMyHospital
        },
        {
          path: '/user',
          name: 'user_settings',
          component: UserSettings
        },
        {
          path: '/quiz',
          name: 'quiz',
          component: Quiz
        },
        {
          path: '/user/edit',
          name: 'user_edit',
          component: UserEdit
        },
        {
          path: '/hospital/users',
          name: 'hospital_all_user_settings',
          component: HospitalAllUserSettings
        },
        {
          path: '/hospital/users/add',
          name: 'hospital_all_user_settings_add',
          component: HospitalAllUserSettingsAddEdit
        },
        {
          path: '/hospital/users/edit',
          name: 'hospital_all_user_settings_edit',
          component: HospitalAllUserSettingsAddEdit
        },
        {
          path: '/hospital/bulk-record-patients',
          name: 'bulk_record_patients',
          component: BulkRecordPatients
        },
        {
          path: '/hospital/bulk-new-user',
          name: 'bulk_new_user',
          component: BulkNewUser
        },
        {
          path: '/wdmlayers',
          name: 'wdmlayers',
          component: WdmLayers
        },
        {
          path: '/layerdetails',
          name: 'layerdetails',
          component: LayerDetails
        },
        {
          path: '/layer_report',
          name: 'layer_report',
          component: LayerReport
        },
        {
          path: '/layerprofiledetails',
          name: 'layerprofiledetails',
          component: LayerProfileDetails
        },
        {
          path: '/wmanager-app-process-list',
          name: 'wmanagerappprocesslist',
          component: WmanagerAppProcessList
        },
        {
          path: '/wruleengine-app-process-list',
          name: 'wruleengineappprocesslist',
          component: WruleengineAppProcessList
        },
        {
          path: '/wanalyzer-app-process-list',
          name: 'wanalyzerappprocesslist',
          component: WanalyzerAppProcessList
        },
        {
          path: '/walgorithm-app-process-list',
          name: 'walgorithmappprocesslist',
          component: WalgorithmAppProcessList
        },
        {
          path: '/layer-wdmr-record-view',
          name: 'layerwdmrrecordview',
          component: LayerWdmrRecordView
        },
        {
          path: '/requestlog',
          name: 'requestlog',
          component: RequestLog
        },
        {
          path: '/userlist',
          name: 'userlist',
          component: UserList
        },
        {
          path: '/useradd',
          name: 'useradd',
          component: UserAdd
        },
        {
          path: '/useredit',
          name: 'useredit',
          component: UserEdit
        },
        {
          path: '/errorlog',
          name: 'errorlog',
          component: ErrorLog
        },
        {
          path: '/layerquery',
          name: 'layerquery',
          component: LayerQuery
        },
        {
          path: '/db_manager',
          name: 'db_manager',
          component: DbManager
        },
        {
          path: '/layerquerysecond',
          name: 'layerquerysecond',
          component: LayerQuerySecond
        },
        {
          path: '/hospital/users/bulk_add',
          name: 'hospital_all_user_settings_bulk_add',
          component: HospitalBulkNewUser
        },
        {
          path: 'theme',
          redirect: '/theme/colors',
          name: 'Theme',
          component: {
            render(c) { return c('router-view') }
          },
          children: [{
              path: 'colors',
              name: 'Colors',
              component: Colors
            },
            {
              path: 'typography',
              name: 'Typography',
              component: Typography
            }
          ]
        },
        {
          path: 'charts',
          name: 'Charts',
          component: Charts
        },
        {
          path: 'widgets',
          name: 'Widgets',
          component: Widgets
        },
        {
          path: 'base',
          redirect: '/base/cards',
          name: 'Base',
          component: {
            render(c) { return c('router-view') }
          },
          children: [{
              path: 'cards',
              name: 'Cards',
              component: Cards
            },
            {
              path: 'forms',
              name: 'Forms',
              component: Forms
            },
            {
              path: 'switches',
              name: 'Switches',
              component: Switches
            },
            {
              path: 'tables',
              name: 'Tables',
              component: Tables
            },
            {
              path: 'tabs',
              name: 'Tabs',
              component: Tabs
            },
            {
              path: 'breadcrumbs',
              name: 'Breadcrumbs',
              component: Breadcrumbs
            },
            {
              path: 'carousels',
              name: 'Carousels',
              component: Carousels
            },
            {
              path: 'collapses',
              name: 'Collapses',
              component: Collapses
            },
            {
              path: 'jumbotrons',
              name: 'Jumbotrons',
              component: Jumbotrons
            },
            {
              path: 'list-groups',
              name: 'List Groups',
              component: ListGroups
            },
            {
              path: 'navs',
              name: 'Navs',
              component: Navs
            },
            {
              path: 'navbars',
              name: 'Navbars',
              component: Navbars
            },
            {
              path: 'paginations',
              name: 'Paginations',
              component: Paginations
            },
            {
              path: 'popovers',
              name: 'Popovers',
              component: Popovers
            },
            {
              path: 'progress-bars',
              name: 'Progress Bars',
              component: ProgressBars
            },
            {
              path: 'tooltips',
              name: 'Tooltips',
              component: Tooltips
            }
          ]
        },
        {
          path: 'buttons',
          redirect: '/buttons/standard-buttons',
          name: 'Buttons',
          component: {
            render(c) { return c('router-view') }
          },
          children: [{
              path: 'standard-buttons',
              name: 'Standard Buttons',
              component: StandardButtons
            },
            {
              path: 'button-groups',
              name: 'Button Groups',
              component: ButtonGroups
            },
            {
              path: 'dropdowns',
              name: 'Dropdowns',
              component: Dropdowns
            },
            {
              path: 'brand-buttons',
              name: 'Brand Buttons',
              component: BrandButtons
            }
          ]
        },
        {
          path: 'icons',
          redirect: '/icons/font-awesome',
          name: 'Icons',
          component: {
            render(c) { return c('router-view') }
          },
          children: [{
              path: 'coreui-icons',
              name: 'CoreUI Icons',
              component: CoreUIIcons
            },
            {
              path: 'flags',
              name: 'Flags',
              component: Flags
            },
            {
              path: 'font-awesome',
              name: 'Font Awesome',
              component: FontAwesome
            },
            {
              path: 'simple-line-icons',
              name: 'Simple Line Icons',
              component: SimpleLineIcons
            }
          ]
        },
        {
          path: 'notifications',
          redirect: '/notifications/alerts',
          name: 'Notifications',
          component: {
            render(c) { return c('router-view') }
          },
          children: [{
              path: 'alerts',
              name: 'Alerts',
              component: Alerts
            },
            {
              path: 'badges',
              name: 'Badges',
              component: Badges
            },
            {
              path: 'modals',
              name: 'Modals',
              component: Modals
            }
          ]
        }
      ]
    },
    {
      path: '/pages',
      redirect: '/pages/404',
      name: 'Pages',
      component: {
        render(c) { return c('router-view') }
      },
      children: [{
          path: '404',
          name: 'Page404',
          component: Page404
        },
        {
          path: '500',
          name: 'Page500',
          component: Page500
        }
      ]
    },
    {
      path: '/login',
      name: 'login',
      component: Login
    },
    {
      path: '/logout',
      name: 'Logout',
      component: Logout
    },
    {
      path: '/register',
      name: 'register',
      component: Register
    },
    {
      path: '/resetpassword',
      name: 'resetpassword',
      component: ResetPassword
    }
  ]
})

/*
* *dev ve asm için ayrı build paramları eklenecek.**/

const IS_LOCAL = process.env.NODE_ENV !== 'production';

// const API_BASE_URL = 'http://10.200.2.2:8890/';

const API_BASE_URL = IS_LOCAL
  // ? 'http://localhost:8900/'
  ? 'http://localhost:8897/'
  : 'https://manager-api.wisdomera.io/';

const API_WMANAGER_URL = IS_LOCAL ?
  'http://localhost:8897/' :
  // 'https://wmanager-api.mlpcare.com/' :
  'https://manager-api.wisdomera.io/';

const API_WANALYZER_URL = IS_LOCAL ?
  'http://localhost:8889/' :
  'https://analyzer-api.wisdomera.io/';
  // 'https://wanalyzer-api.mlpcare.com/';

const API_WALGORITHM_URL = IS_LOCAL ?
  'http://localhost:8899/' :
  'https://algorithm-api.wisdomera.io/';
  // 'https://walgorithm-api.mlpcare.com/';

const API_WRULEENGINE_URL = IS_LOCAL ?
  'http://localhost:8887/' :
  // 'http://10.102.103.175:8887/';
  'https://ruleengine-api.wisdomera.io/';

//  : 'https://whs-api.wisdomera.io/';
//  : 'http://10.200.2.2:8890/';

const CLIENT_ID = 'wisdomhs';
const CLIENT_SECRET = 'wisdomera';
const GRANT_TYPE = 'password';

const WDMR_URL = IS_LOCAL ?
  'http://localhost:8077/' :
  // 'https://www.wdmr.io/' :
  'https://www.wdmr.io/';


const WDMR_LINKS = {
  'record': WDMR_URL + '#/wdmr-record?',
  'show': WDMR_URL + '#/wdmr-show?',
  'wdmr_data_show': WDMR_URL + '#/wdmr-data-show?',
  'wdmr_data_record': WDMR_URL + '#/wdmr-data-record?'
};

const SWIFT_BASE_URL = IS_LOCAL
  ? 'http://localhost:12345/v1/AUTH_test/'
  : 'https://s3.wisdomera.io/v1/AUTH_test/';
  
/*
const API_BASE_URL = IS_LOCAL
  ? 'http://localhost:8888/'
  : 'https://api.wisdomera.io/';

const SWIFT_BASE_URL = IS_LOCAL
  ? 'http://localhost:12345/v1/AUTH_test/'
  : 'https://s3.wisdomera.io/v1/AUTH_test/';

const CLIENT_ID = 'wisdomhs';
const CLIENT_SECRET = 'wisdomera';
const GRANT_TYPE = 'password';
*/

export {
  IS_LOCAL,
  API_BASE_URL,
  SWIFT_BASE_URL,
  CLIENT_ID,
  CLIENT_SECRET,
  GRANT_TYPE,
  API_WMANAGER_URL,
  API_WANALYZER_URL,
  API_WRULEENGINE_URL,
  API_WALGORITHM_URL,
  WDMR_LINKS,
  WDMR_URL
};

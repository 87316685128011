import Vue from 'vue';
import { API_WMANAGER_URL } from '@/config';
export default {
  layer_wdm_anatomy_update(query, data) {
    return Vue.http.post(API_WMANAGER_URL + 'v1/layer/wdm/anatomy/update?' + query, data);
  },
  layer_wdm_update(query) {
    return Vue.http.post(API_WMANAGER_URL + 'v1/layer/wdm/update?' + query);
  },
  layer_user_assign(query, data) {
    return Vue.http.post(API_WMANAGER_URL + 'v1/layer/user/assign?' + query, data);
  },
  layer_settings_operation(query, data) {
    return Vue.http.post(API_WMANAGER_URL + 'v1/layer/settings/operation?' + query, data);
  },
  wdm_sentence_template_record(query, data) {
    return Vue.http.post(API_WMANAGER_URL + 'v1/wdm/sentence/template/record?' + query, data);
  },
  wdm_sentence_template_delete(query, data) {
    return Vue.http.post(API_WMANAGER_URL + 'v1/wdm/sentence/template/delete?' + query, data);
  },
  wdm_sentence_template_list(query) {
    return Vue.http.post(API_WMANAGER_URL + 'v1/wdm/sentence/template/list?' + query);
  },
  save_this_wdmr_to_layer_by_wdmr_id_list(query, data) {
    return Vue.http.post(API_WMANAGER_URL + 'v1/layer/wdmr/save/by_wdmr_id_list?' + query, data);
  },
  layer_wdmr_record(query, data) {
    return Vue.http.post(API_WMANAGER_URL + 'v1/layer/wdmr/record?' + query, data);
  },
  get_wdmr_list_by_wdmr_id(query, data) {
    return Vue.http.post(API_WMANAGER_URL + 'v1/layer/wdmr/list/by_wdmr_id?' + query, data);
  },
  user_wdm_list() {
    return Vue.http.post(API_WMANAGER_URL + 'v1/user/wdm/list');
  },
  user_search_list(data) {
    return Vue.http.post(API_WMANAGER_URL + 'v1/user/search/list', data);
  },
  layer_create(query, data) {
    return Vue.http.post(API_WMANAGER_URL + 'v1/layer/create?' + query, data);
  },
  user_wdm_data_get(query) {
    return Vue.http.post(API_WMANAGER_URL + 'v1/user/wdm/get?' + query);
  },
  layer_data_get(query) {
    return Vue.http.post(API_WMANAGER_URL + 'v1/layer/data/get?' + query);
  },
  get_layer_wdmr(query) {
    return Vue.http.post(API_WMANAGER_URL + 'v1/layer/wdmr/get?' + query);
  },
  get_layer_wdmr_and_wdm(query) {
    return Vue.http.post(API_WMANAGER_URL + 'v1/layer/wdmr/and/wdm/get?' + query);
  },
  get_user_access_token(data) {
    return Vue.http.post(API_WMANAGER_URL + 'v1/oauth/token', data);
  },
  relation_docs_add(data) {
    return Vue.http.post(API_WMANAGER_URL + 'v1/relation_docs/add', data);
  },
  layer_wdmr_list_by_algorithm(data) {
    return Vue.http.post(API_WMANAGER_URL + 'v1/layer/wdmr/list/by_algorithm', data);
  },
  layer_wdmr_list_by_algorithm_and_use_mapping(data) {
    return Vue.http.post(API_WMANAGER_URL + 'v1/layer/wdmr/list/by_algorithm/mapping', data);
  },
  layer_list(query = '') {
    if (query) {
      return Vue.http.get(API_WMANAGER_URL + 'v1/layer/list?' + query);
    } else {
      return Vue.http.get(API_WMANAGER_URL + 'v1/layer/list');
    }
  },
  get_layer_wdm(query) {
    return Vue.http.get(API_WMANAGER_URL + 'v1/layer/wdm?' + query);
  },
  layer_data_profile(query) {
    return Vue.http.get(API_WMANAGER_URL + 'v1/layer/data_profile?' + query);
  },
  get_layer_wdm_param_info(query, data) {
    return Vue.http.post(API_WMANAGER_URL + 'v1/layer/wdmr/param_info?' + query, data);
  },
  get_layer_wdm_param_info_options(query, data) {
    return Vue.http.post(API_WMANAGER_URL + 'v1/layer/wdmr/param_info/options?' + query, data);
  },
  process_info(query) {
    return Vue.http.post(API_WMANAGER_URL + 'v1/process/info?' + query);
  },
  process_info_log_active_process_id_details(query) {
    return Vue.http.post(API_WMANAGER_URL + 'v1/process/info/log/active/process_id/details?' + query);
  },
  process_info_delete_log(query) {
    return Vue.http.post(API_WMANAGER_URL + 'v1/process/info/delete/log?' + query);
  },
  layer_wdm_anatomy_merge(query) {
    return Vue.http.post(API_WMANAGER_URL + 'v1/layer/wdm/anatomy/merge?' + query);
  },
  layer_wdm_merge(query) {
    return Vue.http.post(API_WMANAGER_URL + 'v1/layer/wdm/merge?' + query);
  },
  layer_sentence_template_merge(query) {
    return Vue.http.post(API_WMANAGER_URL + 'v1/layer/sentence/template/merge?' + query);
  },
  layer_wdm_sentence_smart_search_model_merge(query) {
    return Vue.http.post(API_WMANAGER_URL + 'v1/layer/wdm/sentence/smart_search_model/merge?' + query);
  },
  layer_wdm_sentence_schema_merge(query) {
    return Vue.http.post(API_WMANAGER_URL + 'v1/layer/wdm/sentence/schema/merge?' + query);
  },
  get_layer_wdmr_list(query) {
    return Vue.http.post(API_WMANAGER_URL + 'v1/get/layer/wdmr/list?' + query);
  },
  layer_data_analysis(query) {
    return Vue.http.post(API_WMANAGER_URL + 'v1/layer/data/analyze?' + query);
  },
  layer_report_list(query, data) {
    return Vue.http.post(API_WMANAGER_URL + 'v1/layer/get/report/list?' + query, data);
  },
  clear_layer_report(query, data) {
    return Vue.http.post(API_WMANAGER_URL + 'v1/layer/report/clear?' + query, data);
  },
  table_to_excel(data) {
    return Vue.http.post(API_WMANAGER_URL + 'v1/table/to/excel', data);
  },
  layer_user_list(query) {
    return Vue.http.post(API_WMANAGER_URL + 'v1/layer/user/list?' + query);
  },
  layer_transfer_index_list_to_bulk(query) {
    return Vue.http.post(API_WMANAGER_URL + 'v1/layer/transfer/index/list/to_bulk?' + query);
  },
  layer_analyze_index(query) {
    return Vue.http.post(API_WMANAGER_URL + 'v1/layer/analyze/index?' + query);
  },
  layer_make_index_operation(query) {
    return Vue.http.post(API_WMANAGER_URL + 'v1/layer/make/index/operation?' + query);
  },
  layer_active_process_analyze(query) {
    return Vue.http.post(API_WMANAGER_URL + 'v1/layer/active/process/analyze?' + query);
  },
  get_layer_index_list_wdmr_details(query) {
    return Vue.http.post(API_WMANAGER_URL + 'v1/layer/get/index/list/wdmr_details?' + query);
  },
  layer_wdmr_sentence_to_parameter(query) {
    return Vue.http.post(API_WMANAGER_URL + 'v1/layer/wdmr/sentence_to_parameter?' + query);
  },
  get_sub_layer_wdm_list(query) {
    return Vue.http.post(API_WMANAGER_URL + 'v1/layer/get/sub/wdm/list?' + query);
  },
  rule_case_algorithm_get(data) {
    return Vue.http.post(API_WMANAGER_URL + 'v1/rule/case_algorithm/get', data);
  },
  db_get_one_data(data) {
    return Vue.http.post(API_WMANAGER_URL + 'v1/db/get/one_data', data);
  },
  db_upsert_one_data(data) {
    return Vue.http.post(API_WMANAGER_URL + 'v1/db/upsert/one_data', data);
  }
};

import * as types from '../../mutation-types';
import addbulkuser from './routes/addbulkuser';
import allhospitals from './routes/allhospitals';
import allusers from './routes/allusers';
import allusers_old from './routes/allusers_old';
import errorlog from './routes/errorlog';
import layerquery from './routes/layerquery';
import layerquerysecond from './routes/layerquerysecond';
import myhospital from './routes/myhospital';
import newuser from './routes/newuser';
import newuser_old from './routes/newuser_old';
import requestlog from './routes/requestlog';
import db_manager from './routes/db_manager';
import wdmlayers from './routes/wdmlayers';
import layer_report from './routes/layer_report';
import layerdetails from './routes/layerdetails';
import layerprofiledetails from './routes/layerprofiledetails';
import wmanagerappprocesslist from './routes/wmanagerappprocesslist';
import wruleengineappprocesslist from './routes/wruleengineappprocesslist';
import wanalyzerappprocesslist from './routes/wanalyzerappprocesslist';
import walgorithmappprocesslist from './routes/walgorithmappprocesslist';
import layerwdmrrecordview from './routes/layerwdmrrecordview';
import quiz from './routes/quiz';

const state = {
  items: [
    db_manager,
    wdmlayers,
    layerwdmrrecordview,
    layerquery,
    layerquerysecond,
    layer_report,
    layerdetails,
    layerprofiledetails,
    wmanagerappprocesslist,
    wruleengineappprocesslist,
    wanalyzerappprocesslist,
    walgorithmappprocesslist,
    // allhospitals,
    // myhospital,
    allusers,
    newuser,
    // errorlog,
    // requestlog,
    // addbulkuser,
    // allusers_old,
    // newuser_old,
    // quiz
  ]
};

const mutations = {
  [types.EXPAND_MENU] (state, menuItem) {
    if (menuItem.index > -1) {
      if (state.items[menuItem.index] && state.items[menuItem.index].meta) {
        state.items[menuItem.index].meta.expanded = menuItem.expanded;
      }
    } else if (menuItem.item && 'expanded' in menuItem.item.meta) {
      menuItem.item.meta.expanded = menuItem.expanded;
    }
  }
};

export default {
  state,
  mutations
};
